import React from 'react';

const Rutoken3Image = props => (
    <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_1236_1400)">
            <mask id="mask0_1236_1400" maskUnits="userSpaceOnUse" x="6" y="3" width="56" height="56">
                <path fillRule="evenodd" clipRule="evenodd" d="M48.5547 3.2156L36.7696 15.0007L35.3553 13.5865C35.0961 13.3272 34.6718 13.3272 34.4125 13.5865L23.0988 24.9002C10.4369 37.7507 2.37116 50.3702 8.47585 56.4749C14.5805 62.5796 27.2236 54.5374 40.0694 41.8708L51.3831 30.5571C51.6424 30.2978 51.6424 29.8735 51.3831 29.6142L49.9689 28.2L61.754 16.4149C62.0133 16.1557 62.0133 15.7314 61.754 15.4721L49.4975 3.2156C49.2382 2.95632 48.8139 2.95633 48.5547 3.2156ZM15.6082 49.7291C15.6648 50.7756 15.9759 51.8127 16.5369 52.7319C16.5369 52.7319 16.5369 52.7319 16.5416 52.7366C16.5887 52.8121 16.584 52.9016 16.5416 52.9723C16.5369 52.9771 16.5322 52.9818 16.5274 52.9865C16.4237 53.1373 16.3059 53.2835 16.1739 53.4155C14.8775 54.7118 12.7939 54.7213 11.5164 53.4437C10.2389 52.1662 10.2483 50.0826 11.5447 48.7863C11.6861 48.6448 11.837 48.5223 11.9972 48.4091C12.0067 48.3997 12.0161 48.3903 12.0302 48.3856C12.0962 48.3479 12.1717 48.3479 12.2377 48.3761C12.2471 48.3856 12.2565 48.395 12.2659 48.395C13.138 48.8994 14.1044 49.1775 15.0897 49.2388C15.1321 49.2341 15.3442 49.2577 15.4621 49.3755C15.5799 49.4934 15.5988 49.7008 15.5988 49.7197L15.6082 49.7291Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1236_1400)">
                <path d="M36.7695 15.001L48.5546 3.21586C48.8139 2.95659 49.2382 2.95659 49.4975 3.21586L61.754 15.4724C62.0132 15.7317 62.0132 16.1559 61.754 16.4152L49.9689 28.2003L36.7695 15.001Z" fill="#B3B3B3" />
                <path fillRule="evenodd" clipRule="evenodd" d="M48.0851 12.1729C48.3444 12.4321 48.3444 12.8564 48.0851 13.1157L46.6709 14.5299C46.4117 14.7891 45.9874 14.7891 45.7281 14.5299L43.8425 12.6443C43.5832 12.385 43.5832 11.9607 43.8425 11.7014L45.2567 10.2872C45.516 10.028 45.9402 10.028 46.1995 10.2872L48.0851 12.1729ZM52.7992 16.8869C52.5399 16.6276 52.1156 16.6276 51.8564 16.8869L50.4422 18.3011C50.1829 18.5604 50.1829 18.9846 50.4422 19.2439L52.3278 21.1295C52.5871 21.3888 53.0113 21.3888 53.2706 21.1295L54.6848 19.7153C54.9441 19.4561 54.9441 19.0318 54.6848 18.7725L52.7992 16.8869Z" fill="#E6E6E6" />
                <path d="M44.3125 13.1152L46.6695 10.7582M45.2553 14.058L47.6123 11.701M50.9122 19.7149L53.2692 17.3579M51.855 20.6577L54.212 18.3007" stroke="#FABE78" strokeWidth="0.666667" strokeMiterlimit="10" />
                <path d="M42.4258 20.6582L42.8972 20.1868C43.1565 19.9275 43.5807 19.9275 43.84 20.1868L44.7828 21.1296C45.0421 21.3889 45.0421 21.8131 44.7828 22.0724L44.3114 22.5438L42.4258 20.6582Z" fill="#E6E6E6" />
                <path fillRule="evenodd" clipRule="evenodd" d="M23.0988 24.9002C10.4369 37.7507 2.37116 50.3702 8.47585 56.4749C14.5805 62.5796 27.2236 54.5374 40.0694 41.8708L51.3831 30.5571C51.6424 30.2978 51.6424 29.8735 51.3831 29.6142L35.3553 13.5865C35.0961 13.3272 34.6718 13.3272 34.4125 13.5865L23.0988 24.9002Z" fill="#CCCCCC" />
                <path opacity="0.25" fillRule="evenodd" clipRule="evenodd" d="M18.0362 41.7764C17.4092 41.4417 16.7964 41.3003 16.325 41.7717C16.3061 41.7905 16.292 41.8047 16.2779 41.8283C16.2779 41.8283 12.1342 46.6413 10.8096 49.1256C9.89032 50.8462 10.1119 52.482 11.2998 53.6699C12.5962 54.9663 14.2414 55.0936 15.9432 54.1366C18.7574 52.5574 23.1085 48.7249 23.1886 48.6542C23.66 48.1828 23.5328 47.669 23.1839 46.943C23.1698 46.91 22.5381 45.7032 20.94 44.1051C19.5871 42.7522 18.055 41.8047 18.0268 41.7858L18.0362 41.7764Z" fill="white" />
                <path opacity="0.25" d="M33.4646 23.9622L21.7077 35.719C21.8444 35.8369 21.9387 36.0066 21.9529 36.1999C22.0896 37.9016 22.8108 39.5657 24.1072 40.8621C25.4035 42.1584 27.0676 42.8797 28.7694 43.0164C28.9626 43.0305 29.1323 43.1248 29.2502 43.2615L41.007 31.5047C41.5256 30.9861 41.5256 30.1376 41.007 29.6191L35.3502 23.9622C34.8316 23.4437 33.9831 23.4437 33.4646 23.9622Z" fill="white" />
                <path d="M21.2432 35.5447C21.6203 35.5447 21.9173 35.8323 21.9503 36.2047C22.087 37.9065 22.8082 39.5705 24.1046 40.8669C25.4009 42.1633 27.065 42.8845 28.7668 43.0212C29.1392 43.0542 29.4267 43.3606 29.4267 43.7283C29.4267 44.1432 29.0779 44.4731 28.6678 44.4401C26.6407 44.2799 24.6514 43.4219 23.1005 41.871C21.5496 40.3201 20.6916 38.3307 20.5313 36.3037C20.4983 35.8936 20.833 35.54 21.2432 35.5447ZM23.3928 36.3461C23.5483 37.6331 24.1234 38.8776 25.1134 39.8675C26.1033 40.8575 27.3478 41.4232 28.6348 41.5882C29.059 41.6447 29.4314 41.31 29.4362 40.881C29.4362 40.5322 29.1816 40.2211 28.8328 40.1834C27.8381 40.0655 26.8812 39.6271 26.1222 38.8681C25.3632 38.1092 24.9248 37.1428 24.807 36.1576C24.7693 35.8087 24.4581 35.5542 24.1093 35.5542C23.685 35.5542 23.3503 35.9266 23.4022 36.3556L23.3928 36.3461ZM26.2825 36.3886C26.4192 36.9307 26.6926 37.4398 27.1169 37.8641C27.5411 38.2883 28.0502 38.5617 28.5923 38.6984C29.0166 38.8022 29.4314 38.491 29.4314 38.0479L29.4314 37.963C29.4314 37.6519 29.2099 37.3926 28.9035 37.3125C28.6159 37.2324 28.3472 37.0862 28.1209 36.86C27.8947 36.6337 27.7485 36.365 27.6684 36.0774C27.5883 35.771 27.329 35.5495 27.0179 35.5495L26.933 35.5495C26.4993 35.5495 26.1788 35.9643 26.2825 36.3886ZM29.125 35.8559C29.5399 36.2707 30.2187 36.2707 30.6335 35.8559C31.0484 35.441 31.0484 34.7622 30.6335 34.3474C30.2187 33.9325 29.5399 33.9325 29.125 34.3474C28.7102 34.7622 28.7102 35.441 29.125 35.8559Z" fill="#DD2233" />
                <path opacity="0.35" d="M35.8288 14.0578L35.3574 13.5864C35.0981 13.3271 34.7068 13.2941 34.4853 13.5157L22.158 25.8429C19.6973 28.3508 16.5295 31.8675 14.6156 34.3282C12.2915 37.3122 9.90153 40.9279 8.62873 43.4735C10.8443 39.9851 11.6269 41.3144 12.3057 40.447C12.9845 39.5891 12.0653 38.783 12.763 37.925C13.4701 37.0671 14.3987 37.9015 15.1294 37.0859C15.8648 36.2751 14.9031 35.3889 15.6291 34.5498C16.3551 33.7107 17.3639 34.6629 18.137 33.8898C18.9101 33.1167 17.8447 32.0796 18.5895 31.2594C19.339 30.4438 20.4751 31.5799 21.2812 30.8398C22.092 30.1044 20.89 28.8741 21.6725 28.0821C22.455 27.2996 23.7137 28.6242 24.5434 27.9077C25.3777 27.1959 24.0814 25.7958 24.9063 25.0463C25.736 24.3109 27.0465 25.7722 27.8903 25.0698C28.7341 24.3769 27.4331 22.8778 28.3052 22.1848C29.1867 21.5013 30.8555 22.8118 31.6286 22.0387C32.5431 21.1242 30.1861 19.71 31.6003 18.2957L35.8429 14.0531L35.8288 14.0578Z" fill="white" />
                <path opacity="0.1" d="M50.913 29.1421L51.3844 29.6135C51.6437 29.8727 51.6767 30.264 51.4551 30.4856L39.1279 42.8128C36.62 45.2735 33.1034 48.4414 30.6426 50.3553C27.6586 52.6793 24.043 55.0693 21.4974 56.3421C24.9858 54.1265 23.6564 53.344 24.5238 52.6651C25.3817 51.9863 26.1879 52.9056 27.0458 52.2079C27.9038 51.5008 27.0694 50.5721 27.8849 49.8414C28.6957 49.106 29.582 50.0677 30.4211 49.3417C31.2602 48.6158 30.3079 47.607 31.081 46.8339C31.8541 46.0608 32.8912 47.1261 33.7115 46.3813C34.527 45.6318 33.3909 44.4957 34.131 43.6896C34.8664 42.8788 36.0968 44.0809 36.8887 43.2983C37.6713 42.5158 36.3466 41.2572 37.0632 40.4275C37.775 39.5931 39.175 40.8895 39.9246 40.0645C40.66 39.2348 39.1986 37.9243 39.901 37.0805C40.594 36.2367 42.093 37.5378 42.786 36.6657C43.4695 35.7841 42.159 34.1154 42.9321 33.3423C43.8467 32.4277 45.2609 34.7848 46.6751 33.3706L50.9177 29.1279L50.913 29.1421Z" fill="black" />
                <path opacity="0.1" fillRule="evenodd" clipRule="evenodd" d="M11.5191 53.4528C10.4066 52.3403 10.2746 50.615 11.1137 49.3328C11.109 49.3375 11.0996 49.3375 11.0901 49.3375C11.0807 49.3469 11.0713 49.3563 11.0571 49.3611C10.9016 49.4695 10.746 49.5968 10.6046 49.7382C9.30824 51.0345 9.29881 53.1181 10.5763 54.3957C11.8538 55.6732 13.9374 55.6637 15.2338 54.3674C15.3658 54.2354 15.4836 54.0892 15.5873 53.9384C15.5921 53.9337 15.5968 53.929 15.6015 53.9242C15.6109 53.9148 15.6062 53.8912 15.6109 53.8771C14.3287 54.6974 12.6222 54.5654 11.5144 53.4576L11.5191 53.4528Z" fill="black" />
                <path opacity="0.05" fillRule="evenodd" clipRule="evenodd" d="M48.5547 3.2156L36.7696 15.0007L35.3553 13.5865C35.0961 13.3272 34.6718 13.3272 34.4125 13.5865L23.0988 24.9002C10.4369 37.7507 2.37116 50.3702 8.47585 56.4749C14.5805 62.5796 27.2236 54.5374 40.0694 41.8708L51.3831 30.5571C51.6424 30.2978 51.6424 29.8735 51.3831 29.6142L49.9689 28.2L61.754 16.4149C62.0133 16.1557 62.0133 15.7314 61.754 15.4721L49.4975 3.2156C49.2382 2.95632 48.8139 2.95633 48.5547 3.2156Z" fill="url(#paint0_linear_1236_1400)" />
            </g>
        </g>
        <defs>
            <linearGradient id="paint0_linear_1236_1400" x1="23.5702" y1="24.4288" x2="40.5644" y2="41.4229" gradientUnits="userSpaceOnUse">
                <stop stopColor="white" />
                <stop offset="0.5" />
            </linearGradient>
            <clipPath id="clip0_1236_1400">
                <rect width="66.6667" height="24" fill="white" transform="translate(0 47.999) rotate(-45)" />
            </clipPath>
        </defs>
    </svg>
);

export default Rutoken3Image;


