import React from 'react';

const RutokenLabel = props => (
    <svg width="124" height="20" viewBox="0 0 124 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_287_4884)">
            <path d="M19.2241 19.9976L21.8087 14.7484L14.1582 0H20.0754L24.4851 9.492L28.121 0H33.5232L24.5251 19.9976H19.2241Z" fill="#DD2233" />
            <path d="M38.9725 19.9976V4.24271H33.5186L35.3365 0H48.6691V4.24271H43.8196V19.9976H38.9725Z" fill="#DD2233" />
            <path d="M87.22 19.9976L79.6824 9.65898L86.9872 0H81.4792L75.3315 8.88523V4.19097V0H70.4844V19.9976H75.3315V16.5616V10.889L81.2558 19.9976H87.22Z" fill="#DD2233" />
            <path d="M118.96 19.9976V11.5122H111.691V19.9976H106.841V0H111.691V7.87864H118.96V0H123.81V19.9976H118.96Z" fill="#DD2233" />
            <path d="M58.6665 0C56.6887 0 54.7553 0.58649 53.1108 1.6853C51.4663 2.78412 50.1846 4.3459 49.4277 6.17317C48.6708 8.00043 48.4728 10.0111 48.8587 11.9509C49.2445 13.8907 50.1969 15.6725 51.5954 17.0711C52.994 18.4696 54.7758 19.422 56.7156 19.8079C58.6554 20.1937 60.6661 19.9957 62.4933 19.2388C64.3206 18.4819 65.8824 17.2002 66.9812 15.5557C68.08 13.9112 68.6665 11.9778 68.6665 10C68.6665 7.34783 67.6129 4.8043 65.7376 2.92893C63.8622 1.05357 61.3187 0 58.6665 0ZM58.6665 15.4257C57.5928 15.4257 56.5433 15.1073 55.6506 14.5107C54.7579 13.9142 54.0622 13.0663 53.6514 12.0743C53.2407 11.0823 53.1333 9.99082 53.3429 8.93782C53.5526 7.88483 54.0698 6.91765 54.8291 6.15862C55.5885 5.39959 56.5559 4.8828 57.609 4.67363C58.6621 4.46445 59.7536 4.57228 60.7454 4.98347C61.7372 5.39467 62.5847 6.09076 63.1809 6.9837C63.7771 7.87665 64.095 8.92634 64.0945 10C64.0927 11.439 63.5202 12.8186 62.5026 13.8361C61.4851 14.8537 60.1055 15.4262 58.6665 15.428V15.4257Z" fill="#DD2233" />
            <path d="M8.6735 0H0.19043V19.9906H5.03756V13.3325H8.6735C10.4198 13.2997 12.0835 12.583 13.3069 11.3364C14.5303 10.0898 15.2157 8.41291 15.2157 6.66627C15.2157 4.91964 14.5303 3.24275 13.3069 1.99616C12.0835 0.749557 10.4198 0.0328024 8.6735 0ZM7.4623 9.69661H5.03756V3.63594H7.4623C8.25138 3.65792 9.00077 3.98683 9.55112 4.55273C10.1015 5.11863 10.4094 5.87689 10.4094 6.66627C10.4094 7.45566 10.1015 8.21392 9.55112 8.77982C9.00077 9.34572 8.25138 9.67463 7.4623 9.69661Z" fill="#DD2233" />
            <path d="M105.491 8.42663C105.118 6.08692 103.926 3.9559 102.127 2.41351C100.329 0.871118 98.0406 0.0176052 95.6714 0.0051433C93.3021 -0.0073186 91.0053 0.82208 89.1907 2.34547C87.376 3.86886 86.1613 5.98721 85.7632 8.32286C85.3652 10.6585 85.8095 13.0596 87.0171 15.0981C88.2246 17.1366 90.1169 18.68 92.3566 19.4531C94.5963 20.2262 97.0377 20.1788 99.2456 19.3192C101.454 18.4597 103.284 16.844 104.412 14.7601L100.397 12.5706C99.9806 13.3473 99.3803 14.0104 98.6488 14.5022C97.9172 14.994 97.0766 15.2996 96.2 15.3923C95.3234 15.4851 94.4374 15.3622 93.6191 15.0343C92.8009 14.7065 92.0751 14.1836 91.505 13.5113L105.336 8.47601L105.491 8.42663ZM90.2045 10.1199C90.2045 10.08 90.2045 10.0423 90.2045 10.0024C90.2029 8.87837 90.5508 7.78169 91.1999 6.8641C91.849 5.9465 92.7673 5.25337 93.8277 4.88062C94.8881 4.50787 96.0381 4.47395 97.1186 4.78354C98.1991 5.09313 99.1567 5.73092 99.8588 6.60866L90.2045 10.1199Z" fill="#DD2233" />
        </g>
        <defs>
            <clipPath id="clip0_287_4884">
                <rect width="123.619" height="20" fill="white" transform="translate(0.19043)" />
            </clipPath>
        </defs>
    </svg>
);

export default RutokenLabel;