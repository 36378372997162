import React from "react";

const NoDevicesFoundSvg = () => (
    <svg width="206" height="128" viewBox="0 0 206 128" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.42" d="M34.3481 120.807C52.8394 122.001 63.8083 120.807 63.8083 120.807C63.8083 120.807 80.7111 120.634 93.8745 120.599C116.902 120.538 131.432 129.29 152.84 120.807C168.605 114.56 183.428 107.982 187.289 94.3731C199.503 51.3299 168.391 31.8448 138.969 39.6113C119.587 44.7272 113.841 11.8486 83.488 3.93415C83.488 3.93415 65.9275 -1.73342 53.871 13.1704C43.0511 26.5405 59.496 50.0652 40.746 56.3382C29.0121 60.2634 4.64372 61.4104 3.09465 88.0564C2.11011 104.968 15.8568 119.613 34.3481 120.807Z" fill="#E1E1E1" />
        <path d="M181.905 79.8043C182.838 79.2378 183.833 78.8094 184.861 78.519L185.47 75.5915L190.411 75.5059L191.12 78.4095C192.158 78.6666 193.167 79.0569 194.119 79.5901L196.618 77.9526L200.174 81.3846L198.627 83.9361C199.194 84.869 199.622 85.8639 199.912 86.8921L202.84 87.5014L202.926 92.4424L200.022 93.1517C199.765 94.1894 199.374 95.1985 198.841 96.1506L200.479 98.6496L197.047 102.205L194.495 100.658C193.562 101.225 192.568 101.653 191.539 101.944L190.93 104.871L185.989 104.957L185.28 102.053C184.242 101.796 183.233 101.406 182.281 100.873L179.782 102.51L176.226 99.078L177.773 96.5266C177.207 95.5936 176.778 94.5988 176.488 93.5706L173.56 92.9613L173.475 88.0202L176.378 87.3062C176.635 86.2685 177.026 85.2594 177.559 84.3073L175.921 81.8083L179.353 78.2525L181.905 79.8043ZM184.537 86.3875C182.505 88.4963 182.562 91.8522 184.67 93.8847C186.779 95.9173 190.135 95.8602 192.168 93.7514C194.2 91.6427 194.143 88.2868 192.034 86.2542C189.93 84.2217 186.574 84.2788 184.537 86.3875Z" fill="#DCEBED" />
        <path d="M31.7069 14.0192C32.2543 13.6908 32.835 13.4385 33.4348 13.2671L33.7918 11.5582L36.6812 11.5059L37.0954 13.2005C37.6999 13.348 38.2902 13.5813 38.8471 13.8907L40.3084 12.9339L42.3886 14.9427L41.4842 16.4374C41.8126 16.9848 42.0649 17.5655 42.2363 18.1653L43.9452 18.5223L43.9976 21.4117L42.3029 21.8258C42.1554 22.4304 41.9221 23.0206 41.6127 23.5776L42.5695 25.0389L40.5607 27.1191L39.0661 26.2147C38.5186 26.5431 37.9379 26.7954 37.3381 26.9668L36.9811 28.6757L34.0917 28.728L33.6776 27.0334C33.073 26.8858 32.4828 26.6526 31.9259 26.3432L30.4645 27.3L28.3843 25.2912L29.2887 23.7965C28.9603 23.2491 28.708 22.6684 28.5366 22.0686L26.8278 21.7116L26.7754 18.8222L28.47 18.4081C28.6176 17.8035 28.8508 17.2133 29.1602 16.6563L28.2034 15.195L30.2122 13.1148L31.7069 14.0192ZM33.2444 17.8702C32.0544 19.103 32.0877 21.0642 33.3206 22.2542C34.5534 23.4443 36.5146 23.411 37.7047 22.1781C38.8947 20.9452 38.8614 18.984 37.6285 17.794C36.4004 16.604 34.4344 16.6373 33.2444 17.8702Z" fill="#DCEBED" />
        <g clipPath="url(#clip0_608_4755)">
            <path d="M92.1983 103.394L89.7188 120.572H119.166L116.374 103.394H92.1983Z" fill="#88BBC4" />
            <path d="M129.395 123.048H79.4883V124.286H129.395V123.048Z" fill="#288494" />
            <path d="M89.7184 120.572L79.4883 123.048H129.395L119.165 120.572H89.7184Z" fill="#4F8791" />
            <path d="M161.968 94.5472H46.9121V34.9606C46.9121 32.3345 49.046 30.2036 51.6759 30.2036H157.204C159.838 30.2036 161.968 32.3345 161.968 34.9606V94.5472Z" fill="#88BBC4" />
            <path d="M157.223 105.562H51.6571C49.0348 105.562 46.9121 103.439 46.9121 100.82V94.5474H161.968V100.82C161.968 103.439 159.846 105.562 157.223 105.562Z" fill="#BAD7DC" />
            <path d="M157.727 33.9175H51.1582V90.5628H157.727V33.9175Z" fill="#DCEBED" />
            <circle cx="104.424" cy="62.509" r="19.4157" fill="#DD2233" fillOpacity="0.2" />
            <path d="M104.423 76.2253C111.969 76.2253 118.086 70.1082 118.086 62.5623C118.086 55.0165 111.969 48.8994 104.423 48.8994C96.8769 48.8994 90.7598 55.0165 90.7598 62.5623C90.7598 70.1082 96.8769 76.2253 104.423 76.2253Z" fill="#DD2233" />
            <path fillRule="evenodd" clipRule="evenodd" d="M112.62 62.5623L108.977 60.7406V62.198H101.326V61.2871C101.326 60.6841 101.816 60.1941 102.419 60.1941H103.736C103.902 60.9246 104.554 61.4693 105.334 61.4693C106.239 61.4693 106.973 60.7351 106.973 59.8298C106.973 58.9244 106.239 58.1902 105.334 58.1902C104.554 58.1902 103.902 58.7349 103.736 59.4654H102.419C101.417 59.4654 100.597 60.2852 100.597 61.2871V62.198H99.4621C99.2964 61.4675 98.6442 60.9228 97.8645 60.9228C96.9591 60.9228 96.2249 61.6569 96.2249 62.5623C96.2249 63.4677 96.9591 64.2019 97.8645 64.2019C98.6442 64.2019 99.2964 63.6572 99.4621 62.9267H102.601V63.6554C102.601 64.6573 103.421 65.4771 104.423 65.4771H104.969V66.5701H107.884V63.6554H104.969V64.7484H104.423C103.82 64.7484 103.33 64.2584 103.33 63.6554V62.9267H108.977V64.3841L112.62 62.5623Z" fill="white" />
        </g>
        <g clipPath="url(#clip1_608_4755)">
            <mask id="mask0_608_4755" maskUnits="userSpaceOnUse" x="16" y="41" width="20" height="54">
                <path d="M18.7883 42.2342L18.7883 55.3578L17.2303 55.3578C16.9446 55.3578 16.7109 55.594 16.7109 55.8827L16.7109 68.4814C16.8148 82.6863 19.3233 94.2036 26.0489 94.2036C32.9251 94.2036 35.3089 82.6863 35.4076 68.4814L35.4076 55.8827C35.4076 55.594 35.1739 55.3578 34.8882 55.3578L33.3302 55.3578L33.3302 42.2342C33.3302 41.9455 33.0965 41.7092 32.8108 41.7092L19.3077 41.7092C19.022 41.7092 18.7883 41.9455 18.7883 42.2342ZM26.3033 86.3452C26.911 86.8964 27.6537 87.3006 28.469 87.5001L28.4742 87.5001C28.5417 87.5158 28.5885 87.5683 28.6041 87.6313C28.6041 87.6366 28.6041 87.6418 28.6093 87.6471C28.6352 87.7888 28.6508 87.9358 28.6508 88.0828C28.6508 89.5264 27.5082 90.6917 26.1008 90.6917C24.6934 90.6917 23.5508 89.5264 23.5508 88.0828C23.5508 87.9253 23.5664 87.7731 23.5923 87.6208C23.5923 87.6103 23.5975 87.5998 23.5975 87.5893C23.6131 87.5316 23.6547 87.4896 23.7066 87.4686C23.717 87.4633 23.7274 87.4633 23.7326 87.4633C24.4908 87.2586 25.1764 86.8754 25.7528 86.361C25.7736 86.3347 25.9034 86.2297 26.0333 86.2297C26.1631 86.2297 26.293 86.3347 26.3033 86.3452Z" fill="white" />
            </mask>
            <g mask="url(#mask0_608_4755)">
                <path fillRule="evenodd" clipRule="evenodd" d="M33.3309 42.2348L33.3309 55.3584L18.7891 55.3584L18.7891 42.2348C18.7891 41.9461 19.0228 41.7099 19.3084 41.7099L32.8115 41.7099C33.0972 41.7099 33.3309 41.9461 33.3309 42.2348Z" fill="#B3B3B3" />
                <path fillRule="evenodd" clipRule="evenodd" d="M23.9833 48.0091L23.9833 49.5839C23.9833 49.8727 23.7496 50.1089 23.4639 50.1089L21.3865 50.1089C21.1009 50.1089 20.8672 49.8727 20.8672 49.5839L20.8672 48.0091C20.8672 47.7204 21.1009 47.4842 21.3865 47.4842L23.4639 47.4842C23.7496 47.4842 23.9833 47.7204 23.9833 48.0091ZM28.1381 48.0091L28.1381 49.5839C28.1381 49.8727 28.3718 50.1089 28.6574 50.1089L30.7349 50.1089C31.0205 50.1089 31.2542 49.8727 31.2542 49.5839L31.2542 48.0091C31.2542 47.7204 31.0205 47.4842 30.7349 47.4842L28.6574 47.4842C28.3718 47.4842 28.1381 47.7204 28.1381 48.0091Z" fill="#E6E6E6" />
                <path fillRule="evenodd" clipRule="evenodd" d="M27.0989 54.8335L27.0989 55.3584L25.0215 55.3584L25.0215 54.8335C25.0215 54.5447 25.2552 54.3085 25.5408 54.3085L26.5795 54.3085C26.8652 54.3085 27.0989 54.5447 27.0989 54.8335Z" fill="#E6E6E6" />
                <path fillRule="evenodd" clipRule="evenodd" d="M16.7109 68.4814C16.8148 82.6863 19.3233 94.2036 26.0489 94.2036C32.9251 94.2036 35.3089 82.6863 35.4076 68.4814L35.4076 55.8827C35.4076 55.594 35.1739 55.3578 34.8882 55.3578L17.2303 55.3578C16.9446 55.3578 16.7109 55.594 16.7109 55.8827L16.7109 68.4814Z" fill="#88BBC4" />
                <path opacity="0.15" fillRule="evenodd" clipRule="evenodd" d="M23.0702 80.5656C22.5405 80.7283 22.125 80.9908 22.125 81.5157C22.125 81.5367 22.1302 81.5525 22.1302 81.5735C22.1302 81.5735 22.4989 86.5604 23.1377 88.6812C23.5792 90.1511 24.6023 90.9385 25.9111 90.9385C27.3393 90.9385 28.3157 90.0933 28.7259 88.613C29.4063 86.1667 29.6971 81.6102 29.6971 81.521L29.6971 81.5157C29.6971 80.9908 29.344 80.7756 28.7519 80.5656C28.7259 80.5551 27.7132 80.2349 25.9526 80.2349C24.4621 80.2296 23.0962 80.5551 23.0702 80.5656Z" fill="white" />
                <path opacity="0.15" d="M30.2159 62.1819L30.2159 76.8803C30.2159 77.4577 29.7484 77.9302 29.1772 77.9302L22.945 77.9302C22.3737 77.9302 21.9062 77.4577 21.9062 76.8803L21.9062 62.1819C21.9062 61.6044 22.3737 61.132 22.945 61.132L29.1772 61.132C29.7484 61.132 30.2159 61.6044 30.2159 62.1819Z" fill="white" />
                <path opacity="0.15" d="M17.7496 55.358L17.2303 55.358C16.9446 55.358 16.7109 55.5575 16.7109 55.8042L16.7109 69.5315C16.7369 72.2979 16.9291 76.0198 17.2303 78.4555C17.5938 81.4109 18.269 84.7548 18.9701 86.8809C18.269 83.7049 19.4323 84.0094 19.3233 83.1485C19.2246 82.2929 18.2742 82.3558 18.1859 81.4897C18.1028 80.6183 19.074 80.5658 19.0272 79.7049C18.9857 78.844 17.9678 78.886 17.9054 78.0146C17.8431 77.1432 18.9234 77.1117 18.9234 76.2507C18.9286 75.3898 17.7652 75.4056 17.7237 74.5342C17.6873 73.6628 18.939 73.6628 18.9753 72.8019C19.0169 71.941 17.6769 71.9252 17.6717 71.0486C17.6717 70.1771 19.0948 70.2139 19.1571 69.353C19.2246 68.4921 17.7393 68.4343 17.7808 67.5577C17.8327 66.6863 19.3596 66.7703 19.4375 65.9094C19.5206 65.0537 17.9782 64.9435 18.0768 64.072C18.1859 63.2111 19.827 63.0117 19.827 62.1508C19.827 61.1324 17.7496 61.6573 17.7496 60.0825L17.7496 55.358Z" fill="white" />
                <path opacity="0.15" d="M34.3678 55.358L34.8871 55.358C35.1727 55.358 35.4065 55.5575 35.4065 55.8042L35.4065 69.5315C35.3805 72.2979 35.1883 76.0198 34.8871 78.4555C34.5236 81.4109 33.8484 84.7548 33.1473 86.8809C33.8484 83.7049 32.6851 84.0094 32.7889 83.1485C32.8876 82.2929 33.838 82.3558 33.9263 81.4897C34.0094 80.6183 33.0382 80.5658 33.085 79.7049C33.1265 78.844 34.1444 78.886 34.2068 78.0146C34.2691 77.1432 33.1888 77.1117 33.1888 76.2507C33.1836 75.3898 34.347 75.4056 34.3885 74.5342C34.4249 73.6628 33.1732 73.6628 33.1369 72.8019C33.0953 71.941 34.4353 71.9252 34.4405 71.0486C34.4405 70.1771 33.0174 70.2139 32.9551 69.353C32.8876 68.4921 34.3729 68.4343 34.3314 67.5577C34.2795 66.6863 32.7526 66.7703 32.6747 65.9094C32.5916 65.0537 34.134 64.9435 34.0354 64.072C33.9263 63.2006 32.2852 63.0012 32.2852 62.1403C32.2903 61.1324 34.3678 61.6573 34.3678 60.0825L34.3678 55.358Z" fill="black" />
                <path opacity="0.15" fillRule="evenodd" clipRule="evenodd" d="M26.1008 90.6972C24.8751 90.6972 23.852 89.81 23.6079 88.6289C23.6079 88.6342 23.6027 88.6394 23.5975 88.6447C23.5975 88.6552 23.5923 88.6657 23.5923 88.6761C23.5664 88.8231 23.5508 88.9806 23.5508 89.1381C23.5508 90.5817 24.6934 91.7471 26.1008 91.7471C27.5082 91.7471 28.6508 90.5817 28.6508 89.1381C28.6508 88.9911 28.6352 88.8441 28.6093 88.7024C28.6093 88.6971 28.6093 88.6919 28.6041 88.6866C28.5989 88.6761 28.5885 88.6656 28.5833 88.6552C28.3392 89.8205 27.3213 90.6972 26.1008 90.6972Z" fill="black" />
                <path opacity="0.05" fillRule="evenodd" clipRule="evenodd" d="M18.7883 42.2342L18.7883 55.3578L17.2303 55.3578C16.9446 55.3578 16.7109 55.594 16.7109 55.8827L16.7109 68.4814C16.8148 82.6863 19.3233 94.2036 26.0489 94.2036C32.9251 94.2036 35.3089 82.6863 35.4076 68.4814L35.4076 55.8827C35.4076 55.594 35.1739 55.3578 34.8882 55.3578L33.3302 55.3578L33.3302 42.2342C33.3302 41.9455 33.0965 41.7092 32.8108 41.7092L19.3077 41.7092C19.022 41.7092 18.7883 41.9455 18.7883 42.2342Z" fill="url(#paint0_linear_608_4755)" />
            </g>
        </g>
        <defs>
            <linearGradient id="paint0_linear_608_4755" x1="16.7109" y1="67.9564" x2="35.4329" y2="67.9564" gradientUnits="userSpaceOnUse">
                <stop stopColor="white" />
                <stop offset="0.5" stopColor="#88BBC4" />
            </linearGradient>
            <clipPath id="clip0_608_4755">
                <rect width="115.056" height="94.0824" fill="white" transform="translate(46.9102 30.2026)" />
            </clipPath>
            <clipPath id="clip1_608_4755">
                <rect width="52.4944" height="18.6966" fill="white" transform="translate(16.709 94.2031) rotate(-90)" />
            </clipPath>
        </defs>
    </svg>
)

export default NoDevicesFoundSvg;